import React , { useState } from "react";
import { toastAlert } from "../helper/toastAlert";
import { subscribe_now } from "../action/apicontroller"
import { Link } from "react-router-dom";

export default function Footer(props) {

  const [email, setemail] = useState("");

  const mailchange = (e) => {
    setemail(e.target.value)
  }

  async function subscribe(){
    if(email && email!=null && email!=undefined && email!=""){
       const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!email || regex.test(email) === false){
            toastAlert('error', "Enter Valid Email Id", 'network');
        }else{
          let data = {
            "email": email
          }
          let rest = await subscribe_now(data)
          if(rest && rest.data && rest.data.message){
            toastAlert('success',rest.data.message, 'network');
            setemail("");
          }
        }
    }else{
      toastAlert('error', "Enter Valid Email Id", 'network');
    }
  }

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_top">
            {/* <h2>Don't miss out, Be the first to know</h2>
            <p>
              A cryptocurrency (or crypto currency) is a digital asset designed
              to work as a medium of exchange that uses cryptography
            </p>
            <div class="input-group">
              <input
                type="email"
                class="form-control"
                placeholder="Enter your email address"
                aria-label="Email Address"
                aria-describedby="email"
                onChange={mailchange}
                value={email}
              />
              <button type="button" class="input-group-text" id="email" onClick={()=>subscribe()}>Send Now</button>
            </div> */}
            <ul className="footer_social_links">
               <li>
                <a href="https://t.me/bonjourofficialcommunity" target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </li>
              {/*
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="/" target="_blank">
                  <i class="fab fa-youtube"></i>
                </a>
              </li> */}
            </ul>
          </div>
          <div className="footer_panel_bottom">
            <ul className="footer_list_links">
              <li><Link to="/terms">Terms & Conditions</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>              
            </ul>
            <p>Copyright © {new Date().getFullYear()} Bonjour, All rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
