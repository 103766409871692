import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  // Countdown Timer
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hours</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Minutes</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">Seconds</span>
        </span>
      </div>
    );
  };

  async function movebuy(){
    window.location.href="/buy-token"
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header" name="home" id="trigger-about">
        <div className="container">
          <div className="row">
            <div className="col-lg-5" data-aos="fade-up" data-aos-duration="2000">
              <h1>Unlock the brand-new token and yield more success</h1>
              <h5>Your quest to find the top crypto token ends here! </h5>
              <h5>Get your hands on the secure crypto token and begin your trade.</h5>
            </div>
            <div className="col-lg-7" data-aos="fade-up" data-aos-duration="2000">
              <div className="banner_img">
                <img
                  src={require("../assets/images/banner_img.png")}
                  className="img-fluid"
                  alt="Banner"
                />
              </div>
            </div>
          </div>
          {/* <div className="row" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
            <div className="col-md-9 col-lg-7 m-auto">
              <div className="presale_box">
                <div className="presale_box_inner"></div>
                <h2>Token PRE-SALE IS LIVE</h2>                
                <Countdown date={`2023-03-01T20:00:59`} renderer={renderer} />
                <div className="presale_box_outer"></div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="main_wrapper">
        <section name="about" className="about_section">
          <div className="container">
            <div className="row align-items-center" id="trigger-whychoose">
              <div className="col-lg-6" data-aos="fade-right" data-aos-anchor="#trigger-about" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                <h2 className="main_title">
                  Dwell in the crypto world with custom <span>token development</span> services                   
                </h2>
                <p className="inner_para">
                  Harness the power of the crypto token for your mission-driven business and start investing them to raise funds or make profits.
                </p>
                <button className="primary_btn mt-3" onClick={()=>movebuy()} >Buy Token</button>
              </div>
              <div className="col-lg-6" data-aos="fade-left" data-aos-anchor="#trigger-about" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                <div className="rotating_globe_div">
                  <img
                    src={require("../assets/images/globe.png")}
                    className="img-fluid rotating_globe"
                    alt="Globe"
                  />
                  <img
                    src={require("../assets/images/circle_vector.png")}
                    className="img-fluid rotating_globe_vector"
                    alt="Globe"
                  />
                  <img
                    src={require("../assets/images/bonjour_large.png")}
                    className="img-fluid rotating_bonjour_logo"
                    alt="Globe"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="why_choose_section" name="whychoose">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12">
                <h2 className="main_title" data-aos="fade-up" data-aos-anchor="#trigger-whychoose" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                  Why Choose <br />
                  Our <span>Bonjour Token</span>
                </h2>
                <div className="row why_choose_row" data-aos="fade-up" data-aos-anchor="#trigger-whychoose" data-aos-anchor-placement="top-top" data-aos-duration="1000" id="trigger-token-allocation">
                  <div className="col-md-6 col-lg-4">
                    <div className="why_choose_box">
                      <div className="why_choose_box_img">
                        <img
                          src={require("../assets/images/icon_01.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                      </div>
                      <h4>Flexible Performance</h4>
                      <p className="inner_para">
                        Flexibility and the enhanced performance of the tokens are important aspects when it comes to token development. Let's increase the token value together by utilizing high-performance token functions.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="why_choose_box">
                      <div className="why_choose_box_img">
                        <img
                          src={require("../assets/images/icon_02.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                      </div>
                      <h4>Limitless Accessibility</h4>
                      <p className="inner_para">
                        Trustworthy network structure and intrinsic value promote a huge crowd of investors to access without any limits. Advanced added-up options make the system work enormously.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="why_choose_box">
                      <div className="why_choose_box_img">
                        <img
                          src={require("../assets/images/icon_03.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                      </div>
                      <h4>Easy Transaction</h4>
                      <p className="inner_para">
                        BNJR token transactions are generally fast and easy to execute, and can be used for a variety of purposes on the Binance smartchain network and beyond.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Token Allocation */}
        <section className="token_allocation_section mar_top_section">
          <div className="container pt-5 mt-5" name="token">
            <div className="row text-center" data-aos="fade-up" data-aos-anchor="#trigger-token-allocation" data-aos-anchor-placement="top-top" data-aos-duration="1000" id="trigger-token-details">
              <div className="col-md-11 col-lg-7 m-auto">
                <h2 className="main_title">Token Allocation</h2>
                <p className="inner_para">
                  Explore the token functions. We assist in devising a mission-driven, comprehensive approach to the concept of token development.
                </p>
              </div>
            </div>
            <div className="row" data-aos="fade-up" data-aos-anchor="#trigger-token-details" data-aos-anchor-placement="top-top" data-aos-duration="1000">
              <div className="col-md-12 col-lg-10 col-xl-9 m-auto">
                <div className="token_details" id="trigger-token-sale">
                  <div>
                    <label>Token Name</label>
                    <p>Bonjour (BNJR)</p>
                  </div>
                  <div>
                    <label>Token Symbol</label>
                    <p>BNJR</p>
                  </div>
                  <div>
                    <label>Start</label>
                    <p>March 01, 2023</p>
                  </div>
                  {/* <div>
                    <label>End</label>
                    <p>Dec 20, 2021 (12:00AM GMT)</p>
                  </div> */}
                  <div>
                    <label>Acceptable currencies</label>
                    <p>BNB</p>
                  </div>
                  <div>
                    <label>Total Supply</label>
                    <p>2.1 Million</p>
                  </div>
                  <div>
                    <label>Token exchange rate</label>
                    <p>1 BNJR = 2 USD</p>
                  </div>                  
                </div>
                <div className="text-center" onClick={()=>movebuy()}>
                  <button className="primary_btn mt-3"  onClick={()=>movebuy()}  >Buy Token</button>
                </div>
              </div>
            </div>
            <div className="row align-items-center py-5" id="trigger-token-dis">
              <div className="col-md-6 col-lg-6" data-aos="fade-right" data-aos-anchor="#trigger-token-sale" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                <div className="token_sale_img">
                  <img
                    src={require("../assets/images/token_sale_01.png")}
                    className="img-fluid"
                    alt="Icon"
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6" data-aos="fade-left" data-aos-anchor="#trigger-token-sale" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                <div className="token_dis_div">
                  <h2>Token Sale Proceeds</h2>
                  <ul>
                    <li>
                      <span>PreSale</span>
                      <span>11%</span>
                    </li>
                    <li>
                      <span>Liquidity</span>
                      <span>5%</span>
                    </li>
                    <li>
                      <span>Marketing</span>
                      <span>10%</span>
                    </li>
                    <li>
                      <span>Team</span>
                      <span>10%</span>
                    </li>
                    <li>
                      <span>Airdrop</span>
                      <span>5%</span>
                    </li>
                    <li>
                      <span>Staking & Farming</span>
                      <span>30%</span>
                    </li>
                    <li>
                      <span>NFT Stakers</span>
                      <span>10%</span>
                    </li>
                    <li>
                      <span>Future AI Project Development</span>
                      <span>10%</span>
                    </li>
                    <li>
                      <span>Leaders Bonus</span>
                      <span>9%</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="row align-items-center py-5">
              <div className="col-md-6 col-lg-6" data-aos="fade-right" data-aos-anchor="#trigger-token-dis" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                <div className="token_dis_div" id="trigger-roadmap">
                  <h2>Token Distribution</h2>
                  <ul>
                    <li>
                      <span>ICO Sale</span>
                      <span>24.25%</span>
                    </li>
                    <li>
                      <span>Build Out</span>
                      <span>10.15%</span>
                    </li>
                    <li>
                      <span>Team & Advisers</span>
                      <span>24.25%</span>
                    </li>
                    <li>
                      <span>Private investors</span>
                      <span>25.10%</span>
                    </li>
                    <li>
                      <span>Bounty</span>
                      <span>16.25%</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6" data-aos="fade-left" data-aos-anchor="#trigger-token-dis" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                <div className="token_sale_img">
                  <img
                    src={require("../assets/images/token_sale_02.png")}
                    className="img-fluid"
                    alt="Icon"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <section className="roadmap_section" name="roadmap">
          <div className="container">
            <div className="row" data-aos="fade-up" data-aos-anchor="#trigger-roadmap" data-aos-anchor-placement="top-top" data-aos-duration="1000">
              <div className="col-md-12" id="trigger-roadmap-box">
                <h2 className="main_title">
                  <span>Bonjour</span> Strategy and <br /> Project Plan
                </h2>
              </div>
            </div>
            <div className="row align-items-center roadmap_box_wrap">
              {/* <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="roadmap_box">
                  <div class="roadmap_box_top">
                    <h2>Phase 1</h2>
                    <h3>10th Feb 2023</h3>
                    <img
                      src={require("../assets/images/roadmap_icon_01.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                    <h4>Token Creation</h4>
                  </div>
                  <div className="roadmap_box_content">
                    <p>
                      Secured and powerful tokens are created to embellish your
                      crypto business seamlessly. Explore our tokens!
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 col-lg-6 col-xl-3" data-aos="fade-up" data-aos-anchor="#trigger-roadmap-box" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                <div className="roadmap_box">
                  <div class="roadmap_box_top">
                    <h2>Phase 1</h2>
                    <h3>31st March 2023</h3>
                    <img
                      src={require("../assets/images/roadmap_icon_02.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                    <h4>Autopool</h4>
                  </div>
                  <div className="roadmap_box_content">
                    <p>
                    Bonjour’s special offerings! Activates high performance and rewards top participants with additional income.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3" data-aos="fade-up" data-aos-anchor="#trigger-roadmap-box" data-aos-anchor-placement="top-top" data-aos-duration="1000" data-aos-delay="200">
                <div className="roadmap_box">
                  <div class="roadmap_box_top">
                    <h2>Phase 2</h2>
                    <h3>15th April 2023</h3>
                    <img
                      src={require("../assets/images/roadmap_icon_03.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                    <h4>Lottery and Prediction</h4>
                  </div>
                  <div className="roadmap_box_content">
                    <p>
                      Maximize the growth level by acquiring profits. Be the
                      luckiest to Procure the lottery sale and win exciting
                      rewards.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3" data-aos="fade-up" data-aos-anchor="#trigger-roadmap-box" data-aos-anchor-placement="top-top" data-aos-duration="1000" data-aos-delay="400">
                <div className="roadmap_box">
                  <div class="roadmap_box_top">
                    <h2>Phase 3</h2>
                    <h3>30th April 2023</h3>
                    <img
                      src={require("../assets/images/roadmap_icon_04.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                    <h4>Staking</h4>
                  </div>
                  <div className="roadmap_box_content">
                    <p>
                      Dig up and yield the returns of farming and staking as
                      passive income with Bonjour. Get ready to seize the
                      greatest returns! 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3" data-aos="fade-up" data-aos-anchor="#trigger-roadmap-box" data-aos-anchor-placement="top-top" data-aos-duration="1000" data-aos-delay="600">
                <div className="roadmap_box" id="trigger-faq">
                  <div class="roadmap_box_top">
                    <h2>Phase 4</h2>
                    <h3>20th May 2023</h3>
                    <img
                      src={require("../assets/images/roadmap_icon_05.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                    <h4>NFT</h4>
                  </div>
                  <div className="roadmap_box_content" id="trigger-faq-accordian">
                    <p>Own up the digital collectibles and achieve the irrefutable ownership of digital assets.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq_section" name="faq">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12 col-xl-12" data-aos="fade-up" data-aos-anchor="#trigger-faq" data-aos-anchor-placement="top-top" data-aos-duration="1000">
                <h2 className="main_title">Question and Answer</h2>
                <p className="inner_para">
                  You can send email with your questions and we'll give your
                  answer
                </p>
              </div>
            </div>
          </div>
          <div className="faq_wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-10 m-auto" data-aos="fade-up" data-aos-anchor="#trigger-faq" data-aos-anchor-placement="top-top" data-aos-duration="1000" data-aos-delay="600">
                  <div className="accordion" id="faq_accodian">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is a presale?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            A presale is an initial sale of tokens or coins before the official public sale. It allows investors to purchase tokens at a discounted price before the general public has the opportunity to buy them.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How do I participate in the presale?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            To participate in the presale, you will typically need to register on the website and provide your name, email address, and other basic information. You will also need to have the required cryptocurrency or fiat currency to purchase the tokens during the presale.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What are the benefits of participating in the presale?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            Participating in the presale can offer investors the opportunity to purchase tokens at a discounted price before they are available to the general public. This can also provide early access to a new project or platform.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          When will the presale end and when will tokens be distributed?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>
                            The presale end date and token distribution date should be provided on the presale website. These dates can be subject to change based on the progress of the project.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
