import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Terms(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="row">
              <div className="col-lg-12 col-xl-12 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Terms of use</h2>
                </div>
                <div className="cms_section">
                  <h2>Introduction</h2>
                  <p>The terms of service regulate the use of the platform and services offered by Bonjour. By utilizing our platform’s services, users get agreed to the terms and our privacy policy. If users do not agree to the terms and conditions then they failed to use our platform and services.</p>
                  <h2>Account Creation and User Responsibility</h2>
                  <p>To make avail of our platform and services, you need to create an account and provide accurate information about yourself. You are solely answerable for maintaining the confidentiality of your account and password. You accept to promptly inform us of any unauthorized use of your account and password.</p>
                  <h2>Content and Conduct</h2>
                  <p>You are not allowed to make use of our platform and services for any sort of illegal purpose or cannot transfer any harmful, unlawful, deceptive, defamatory, or offensive content. You may not use our platform to harm minors and also you should not indulge in any false or misleading activities that promote hate or violence.</p>
                  <h2>Intellectual Property</h2>
                  <p>The content, data, and infographics(text, graphics, logos, images, and software) on our platform and services is the complete property of Bonjour, it is protected by the copyright and other intellectual laws. You are prohibited from using, reproducing, modifying, displaying, or distributing any such content without our prior written consent.</p>
                  <h2>Termination of Service</h2>
                  <p>If any users violate these terms, then they are liable for the termination of their account and use of our platform and services. We shall wind up your use of our platform and services at any time with or without warning for any type of delusive activities.</p>
                  <h2>Disclaimers and Limitation of Liability</h2>
                  <p>We do not justify that our platform and services will be continuous or error-free, and we will not be liable for any interruption in between or in case of any errors. We will not be accountable for any damages arising from the use of our platform and services.</p>
                  <h2>Indemnification</h2>
                  <p>You agree to the compensation and hold Bonjour and its associates, officers, agents, and employees inoffensive from any claims, liabilities, ruins, losses, and expenses including reasonable attorneys’ fees, arising from your utilization of our platform and services or your violation of these Terms.</p>
                  <h2>Governing Law</h2>
                  <p>These terms of use conditions of our platform and services will be controlled, regulated, and structured in accordance with the laws of the Delaware governing jurisdiction.</p>
                  <h2>Changes to Terms of Service</h2>
                  <p>We update the Privacy Policy and keep it up to the minute to show changes in our customs or legal requirements. We will notify users of any material modifications by posting the updated version of the Privacy Policy on our platform.</p>
                  <h2>Contact Us</h2>
                  <p>If you have any questions or concerns about our Terms of Service, please contact us at mailto: <a href="mailto:support@webonjour.com">support@webonjour.com</a></p>                  
                  <p className="mt-5">Effective Date: 28 February  2023</p>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
