import React, { useEffect, useState , useRef } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import DataTable from 'react-data-table-component';
import $ from "jquery";
import config from "../config/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import moment from "moment";
import { toastAlert } from "../helper/toastAlert";
import { getCurAddr , updatebuy_history , get_buyhistory } from "../action/apicontroller";
import icoabi from "../ABI/icoABI";
import busdabi from "../ABI/busdABI";
import tokenabi from "../ABI/tokenABI";
import oldtokenabi from "../ABI/oldtokenABI";
import bonjourabi from "../ABI/bonjourmlmabi";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

// Transaction History Table 
const rowdata = [
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '3,000.00', earnedToken: "500",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '2,050.00', earnedToken: "10000",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '3,000.00', earnedToken: "100000",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '5,100.50', earnedToken: "999999",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '3,000.00', earnedToken: "378520",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '3,000.00', earnedToken: "685123",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '3,000.00', earnedToken: "247963",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '3,000.00', earnedToken: "7412",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '3,000.00', earnedToken: "789456",},
  { datetime: "Feb 21, 2023 / 04:13PM", transID: "248962357128", type: "BNB", amount: '3,000.00', earnedToken: "999999",},
  ];
 

export default function BuyToken(props) {
  const [account, setaccount] = useState('');
  const [status, setstatus] = useState(false);
  const [data, setdata] = useState({});
  const [progressstatus, setprogressstatus] = useState("false");
  const [claimprogressstatus, setclaimprogressstatus] = useState("false");
  const [currency,setcurrency] =  useState('bnb');
  const [outputvalue,setoutputvalue] = useState(0);
  const [inputvalue,setinputvalue] = useState(0);
  const [input_ca_value,setinput_ca_value] = useState(0);
  const [history,sethistory] = useState([]);
  const [referral,setreferral] = useState("");
  const [userinfo,setuserinfo] = useState({});
  const [bonjouruserinfo,setbonjouruserinfo] = useState({});
  const [user_old_bal,setuser_old_bal] = useState(0);

  const timerRef = useRef(null);
  useEffect(() => {
    getdetails();
  },[]);

   let columns = [
    {
      name: 'Date & Time',
      selector: 'createdAt',
      sortable: false,
      cell: (item) => {
        return moment(item.createdAt).format('MMM Do YYYY / h:mm A')
      },
      
    },
    {
      name: 'Transaction Hash',
      selector: 'hash',
      sortable: false,
      cell: (item) => {
        return <a href={config.blockchainurl+item.hash} target="_blank" className="hash_address">{item.hash}</a>
      },
    },
    {
      name: 'Token Type',
      selector: 'currency',
      sortable: false,
      cell: (item) => {
        if(item.currency == "bnb"){
          return "BNB"
        }else{
          return "BUSD"
        }
      },
    },
    {
      name: 'Amount',
      selector: 'depositamount',
      sortable: false,
    },
    {
      name: 'Earned Token (BNJR)',
      selector: 'earnedamount',
      sortable: false,
    },
  ];

  async function getdetails() {
    let datas = await getCurAddr();
    setstatus(true);
    console.log(datas,localStorage.getItem("account"),"=====newconsole")
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" && localStorage.getItem("account")) {
    console.log(datas,localStorage.getItem("account"),"=====newinsideeeconsole")
      //const web3 = new Web3(data && data.provider  && data.provider!=null && data.provider!=undefined && data.provider!=""? data.provider : window.ethereum );
      const web3 = new Web3("https://bsc-dataseed1.ninicoin.io");
      const icoContract = new web3.eth.Contract(icoabi, config.icocontract);
      const oldtokenContract = new web3.eth.Contract(oldtokenabi, config.oldtokencontract);
      const bonjourcontract = new web3.eth.Contract(bonjourabi, config.bonjourcontract);
      let bonjouruser = await bonjourcontract.methods.users(localStorage.getItem("account")).call();
      let user_det = await icoContract.methods.userInfo(localStorage.getItem("account")).call();
      let user_oldbal = await oldtokenContract.methods.balanceOf(localStorage.getItem("account")).call();
      setuser_old_bal(user_oldbal);
      setuserinfo(user_det);
      setbonjouruserinfo(bonjouruser);
      setdata(datas);
      let inputdata = {
        "address":datas.address
      }
      let rec = await get_buyhistory(inputdata);
      if(rec && rec.data && rec.data.data){
        sethistory(rec.data.data)
      }else{
        sethistory([])
      }
    }
  }

  const referralchange = async (e) =>{
    setreferral(e.target.value);
  }

  const currencychange = async (e) =>{
    setcurrency(e.target.value);
    if(input_ca_value>0){
      const web3 = new Web3(data && data.provider  && data.provider!=null && data.provider!=undefined && data.provider!=""? data.provider : window.ethereum );
      const icoContract = new web3.eth.Contract(icoabi, config.icocontract);
      var amt =web3.utils.toWei(parseFloat(input_ca_value).toString());
      amt = convert(amt);
      if(e.target.value == "busd"){
        let results = await icoContract.methods.getTokenfromBusd(amt.toString()).call();
        setoutputvalue(results/1000000000000000000);
      }else{
        let results = await icoContract.methods.getTokenfromBnb(amt.toString()).call();
        setoutputvalue(results/10000000000);
      }
    }else{
      setoutputvalue(0)
    }
  }

  const inputchange = async (e) =>{
    setinputvalue(e.target.value)
    setinput_ca_value(e.target.value)
    if(e.target.value>0){
      const web3 = new Web3(data && data.provider  && data.provider!=null && data.provider!=undefined && data.provider!=""? data.provider : window.ethereum );
      const icoContract = new web3.eth.Contract(icoabi, config.icocontract);
      var amt =web3.utils.toWei(parseFloat(e.target.value).toString());
      amt = convert(amt);
       if(currency == "busd"){
        let results = await icoContract.methods.getTokenfromBusd(amt.toString()).call();
        setoutputvalue(results/1000000000000000000);
      }else{
        let results = await icoContract.methods.getTokenfromBnb(amt.toString()).call();
        setoutputvalue(results/10000000000);
      }
    }else{
      setoutputvalue(0);
    }
  }

  const outputchange = async (e) => {
    setoutputvalue(e.target.value)
    if(e.target.value>0){
      const web3 = new Web3(data && data.provider  && data.provider!=null && data.provider!=undefined && data.provider!=""? data.provider : window.ethereum );
      const icoContract = new web3.eth.Contract(icoabi, config.icocontract);
      var amt = web3.utils.toWei(parseFloat(e.target.value).toString());
      if(currency == "busd"){
          let pp = await icoContract.methods.getTokenprice().call();
          let cal_price_0 = (e.target.value)/(parseFloat(pp)/1000000)
          let cal_price = parseFloat(cal_price_0).toFixed(4)
          setinputvalue(cal_price);
          setinput_ca_value(parseFloat(cal_price_0));
      }else{
          let pp = await icoContract.methods.getBnbPrice().call();
          let tokenbusd = await icoContract.methods.getTokenprice().call();
          let last_0 = parseFloat(e.target.value)/(tokenbusd/1000000)
          let last = parseFloat(last_0 / (pp/1e16 )).toFixed(4);
          let saved_one  = parseFloat(last_0 / (pp/1e16 ))
          setinputvalue(last);
          setinput_ca_value(parseFloat(saved_one));
      }
    }else{
      setinputvalue(0);
      setinput_ca_value(0);
    }
  }

  async function buysubmit(){
    try{
      if(bonjouruserinfo && bonjouruserinfo.isExist){
        let ref_address = userinfo && userinfo.isExist ? userinfo.referral : referral;
        setprogressstatus("true");
        if(outputvalue && outputvalue>0){
          if(input_ca_value && input_ca_value>0){
            let datas = await getCurAddr();
            if(datas && datas.address && datas.address != undefined && datas.address != null && datas.address != "" && localStorage.getItem("account")){
              const web3 = new Web3(datas && datas.provider  && datas.provider!=null && datas.provider!=undefined && datas.provider!=""? datas.provider : window.ethereum );
              if(web3.utils.isAddress(ref_address)){
                if ((ref_address).toLowerCase() != (datas.address).toLowerCase()) {
                const icoContract = new web3.eth.Contract(icoabi, config.icocontract);
                const busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
                const tokenContract = new web3.eth.Contract(tokenabi, config.tokencontract);
                const bonjourcontract = new web3.eth.Contract(bonjourabi, config.bonjourcontract);
                let bonjourrefuser = await bonjourcontract.methods.users(ref_address).call();
                if(bonjourrefuser && bonjourrefuser.isExist){
                var amount = web3.utils.toWei(parseFloat(input_ca_value).toString());
                amount = convert(amount);
                let adminbalance = await tokenContract.methods.balanceOf(config.icocontract).call();
                var adminbal = parseFloat(adminbalance)/1e18;
                 if(parseFloat(adminbal)>=parseFloat(outputvalue)){
                  if(currency=="bnb"){
                    if(parseFloat(datas.bnbvalue/1e18)>parseFloat(input_ca_value)){
                      icoContract.methods.depositBNB(ref_address).send({from:datas.address,value:amount.toString()}).then(async function(depostrest){
                        if(depostrest && depostrest.status && depostrest.transactionHash){
                          let inputdata = {
                            "address" : datas.address,
                            "currency": currency,
                            "depositamount" : input_ca_value,
                            "earnedamount"  : outputvalue,
                            "hash" : depostrest.transactionHash
                          }
                          await updatebuy_history(inputdata);
                          toastAlert('success', "Successfully Done", 'success');
                          setprogressstatus("false");
                          window.location.reload();
                        }else{
                          toastAlert('error', "Process Cancelled", 'error');
                          setprogressstatus("false");
                        }
                      }).catch (function (error){
                          toastAlert('error', "Process Cancelled", 'error');
                          setprogressstatus("false");
                      })
                    }else{
                      if(parseFloat(datas.bnbvalue/1e18)==parseFloat(input_ca_value)){
                        toastAlert('error', "Insufficient Balance for Gas Fee", 'error');
                        setprogressstatus("false");
                      }else{
                        toastAlert('error', "Insufficient Balance", 'error');
                        setprogressstatus("false");
                      }
                    }
                  }else{
                    if(parseFloat(datas.busdvalue/1e18)>=parseFloat(input_ca_value)){
                      let allowance = await busdContract.methods.allowance(datas.address,config.icocontract).call();
                      let app_value = convert(1000000000000000000000000000);
                      let approve =  allowance >= amount ? "true" : await busdContract.methods.approve(config.icocontract,(app_value).toString()).send({from:datas.address});
                        if((approve && approve.status==true)||approve=="true"){
                          icoContract.methods.depositBUSD(amount.toString(),ref_address).send({from:datas.address}).then(async function(depostrest){
                             if(depostrest && depostrest.status && depostrest.transactionHash){
                              let inputdata = {
                                "address" : datas.address,
                                "currency": currency,
                                "depositamount" : input_ca_value,
                                "earnedamount"  : outputvalue,
                                "hash" : depostrest.transactionHash
                              }
                              await updatebuy_history(inputdata);
                              toastAlert('success', "Successfully Done", 'success');
                              setprogressstatus("false");
                              window.location.reload();
                            }else{
                              toastAlert('error', "Process Cancelled", 'error');
                              setprogressstatus("false");
                            }
                          }).catch (function (error){
                              toastAlert('error', "Process Cancelled", 'error');
                              setprogressstatus("false");
                          })
                        }else{
                          toastAlert('error', "Process Cancelled", 'error');
                          setprogressstatus("false");
                        }
                    }else{
                      toastAlert('error', "Insufficient Balance", 'error');
                      setprogressstatus("false");
                    }
                  }
                 }else{
                    toastAlert('error', "Admin Does not have enough Token Balance", 'error');
                    setprogressstatus("false");
                 }
                }else{
                  toastAlert('error', "Referral User need to participate in MLM", 'error');
                  setprogressstatus("false");
                }
                }else{
                  toastAlert('error', "Your wallet and Referral wallet both are Same", 'error');
                  setprogressstatus("false");
                }
               }else{
                 toastAlert('error', "Please Enter Valid Referral Address", 'error');
                 setprogressstatus("false");
               }
            }else{
              toastAlert('error', "Please Login to Proceed next", 'error');
              setprogressstatus("false");
            }
          }else{
            toastAlert('error', "Please enter valid Amount", 'error');
            setprogressstatus("false");
          }
        }else{
          toastAlert('error', "Please enter valid Amount", 'error');
          setprogressstatus("false");
        }

      }else{
        toastAlert('error', "Users Participate in MLM only able to Buy Token", 'error');
        setprogressstatus("false");
      }
    }catch(err){
       console.log(err,"====rrrree")
       toastAlert('error', "Process Cancelled", 'error');
       setprogressstatus("false");
    }
  }

  async function claim(){
     try{
      setclaimprogressstatus("true");
      if(user_old_bal && user_old_bal>0){
          let datas = await getCurAddr();
          if(datas && datas.address && datas.address != undefined && datas.address != null && datas.address != "" && localStorage.getItem("account")){
            const web3 = new Web3(datas && datas.provider  && datas.provider!=null && datas.provider!=undefined && datas.provider!=""? datas.provider : window.ethereum );
            const icoContract = new web3.eth.Contract(icoabi, config.icocontract);
            const tokenContract = new web3.eth.Contract(tokenabi, config.tokencontract);
            const tokenoldContract = new web3.eth.Contract(oldtokenabi, config.oldtokencontract);
            let adminbalance = await tokenContract.methods.balanceOf(config.icocontract).call();
             if(parseFloat(adminbalance)>=parseFloat(user_old_bal)){
                  let app_value = convert(1000000000000000000000000000);
                  await tokenoldContract.methods.approve(config.icocontract,(app_value).toString()).send({from:datas.address});
                  icoContract.methods.airdropNewTokens().send({from:datas.address}).then(async function(depostrest){
                    if(depostrest && depostrest.status && depostrest.transactionHash){
                      toastAlert('success', "Successfully Done", 'success');
                      setclaimprogressstatus("false");
                      window.location.reload();
                    }else{
                      toastAlert('error', "Process Cancelled", 'error');
                      setclaimprogressstatus("false");
                    }
                  }).catch (function (error){
                      toastAlert('error', "Process Cancelled", 'error');
                      setclaimprogressstatus("false");
                  })
             }else{
                toastAlert('error', "Admin Does not have enough Token Balance", 'error');
                setclaimprogressstatus("false");
             }
          }else{
            toastAlert('error', "Please Login to proceed next", 'error');
            setclaimprogressstatus("false");
          }
      }else{
        toastAlert('error', "Please enter valid Amount", 'error');
        setclaimprogressstatus("false");
      }
    }catch(err){
       console.log(err,"====rrrree")
       toastAlert('error', "Process Cancelled", 'error');
       setclaimprogressstatus("false");
    }
  }

  function convert(n){
    var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead,decimal,pow] = n.toString()
     .replace(/^-/,"")
     .replace(/^([0-9]+)(e.*)/,"$1.$2")
     .split(/e|\./);
     return +pow < 0
    ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
    : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
  }

  async function empty(){

  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="row">
              <div className="col-lg-10 col-xl-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Buy Token</h2>
                </div>
                <div className="buy_token_box">
                  <div class="buy_token_box_inner"></div>
                  <form class="row g-3">
                   <div class="col-12">
                      <div className="d-flex justify-content-between">
                        <label for="inputState" class="form-label">
                          Select Token
                        </label>
                        <label>
                          Wallet Balance: <span>{currency=="bnb" && data && data.bnbvalue ?(parseFloat(data.bnbvalue)/1e18).toFixed(2):data && data.busdvalue ?(parseFloat(data.busdvalue)/1e18).toFixed(2):0} {currency=="bnb"?"BNB":"BUSD"}</span>
                        </label>
                      </div>
                      <select id="inputState" class="form-select" onChange={currencychange}>
                        <option selected value="bnb">BNB</option>
                        <option value="busd">BUSD</option>
                      </select>
                    </div> 
                  { userinfo && !userinfo.isExist && 
                    <div class="col-12">
                      <div className="d-flex justify-content-between">
                        <label for="inputState" class="form-label">
                          Referral Wallet
                        </label>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        id="referral"
                        value={referral}
                        onChange={referralchange}
                      />
                    </div>}
                    <div class="col-md-6">
                      <label for="depositamount" class="form-label">
                        Enter Deposit Amount {currency=="bnb"?"(BNB)":"(BUSD)"}
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="depositamount"
                        value={inputvalue}
                        onChange={inputchange}
                        min="0"
                      />
                    </div>
                    <div class="col-md-6">
                      <label for="getamount" class="form-label">
                        You Will Get (BNJR)
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="getamount"
                        value={outputvalue}
                        onChange={outputchange}
                        min="0"
                      />
                    </div>
                    <div class="text-center">
                      { progressstatus == "true" ?
                          <button class="primary_btn mt-3" type="button">Please Wait.. In Progress...,</button>
                        :
                        data && localStorage.getItem("account") && data.address && data.address!=null && data.address!=undefined && data.address!="" ? 
                          <button class="primary_btn mt-3" type="button" onClick={(claimprogressstatus == "true" || progressstatus == "true"  ) ? ()=>empty() : ()=>buysubmit()}>Buy Token</button>
                        :
                          <button class="primary_btn mt-3" type="button"  data-bs-toggle="modal"
                           data-bs-target="#connect_wallet_modal">Connect Wallet</button>
                      }
                      {  parseFloat(user_old_bal)>0 && 
                          <button class="primary_btn mt-3" type="button" onClick={(claimprogressstatus == "true" || progressstatus == "true"  ) ? ()=>empty() : ()=>claim()}>{claimprogressstatus == "true" ? "Please Wait .. "  : "Claim Token"}</button>
                      }
                    </div>
                  </form>
                  <div class="buy_token_box_outer"></div>
                </div>
              </div>
            </div>

            <div className="row pt-3 mt-5">
              <div className="col-lg-12">
                <div className="title_flex">
                  <h2 className="inner_title">Transaction History</h2>
                </div>
                <div className="custom_table">
                  <DataTable columns={columns} data={history} noHeader pagination/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
