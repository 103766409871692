import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Home from './pages/home.js';
import BuyToken from './pages/buy-token';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "home", element: <Home /> },
    { path: "buy-token", element: <BuyToken /> },
    { path: "terms", element: <Terms /> },
    { path: "privacy", element: <Privacy /> },
  ]);
  return routes;
};

AOS.init();

const AppWrapper = () => {
  return (
    <Router basename="/">
      <ToastContainer />
      <App />
    </Router>
  );
};

export default AppWrapper;