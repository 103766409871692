import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacy(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="row">
              <div className="col-lg-12 col-xl-12 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Privacy Policy</h2>
                </div>
                <div className="cms_section">
                  <h2>Introduction</h2>
                  <p>Bonjour is determined to protect the privacy of our users. The privacy policy discloses how we process the operations and how we maintain the personal information of users from our platform and services.</p>
                  <h2>Gathering of data</h2>
                  <p>We obtain personal data from users while they create an account on our platform or when they purchase our products and services and also during their participation in any community activities. The information we gather will include the name, email address, postal address, phone number, and payment information.</p>
                  <h2>Information applies</h2>
                  <p>We use the obtained personal information to offer our services, communicate with users, and enhance the user experience on our platform. We also use this information to solve disputes, provide resolutions, enforce our agreements, and comply with legal obligations.</p>
                  <h2>Information exchange</h2>
                  <p>We do not expose personal information to third parties but we may share personal information with third-party service providers who would carry out the services on our welfare such as payment processing and customer support. These third-party service providers are lawfully duty-bound to protect the privacy and security of the user data and will only use it with the objective of granting services to us.</p>
                  <h2>Data withholding</h2>
                  <p>We store personal information for as long as we need it to provide our services and achieve the intentions defined in this privacy policy. We may also withhold individual data to comply with legal obligations, resolve disputes, and enforce our agreements.</p>
                  <h2>Security</h2>
                  <p>We follow relevant technical and organizational measures to defend private data against unauthorized or unlawful processing and the contrary of accidental loss, destruction, or damage.</p>
                  <h2>Changes to Privacy Policy</h2>
                  <p>We upgrade the Privacy Policy and keep it up to date to display changes in our customs or legal requirements. We will apprise users of any material modifications by posting the updated version of the Privacy Policy on our platform.</p>
                  <h2>Contact Us</h2>
                  <p>If you have any queries or concerns regarding our Privacy Policy, please connect us at mailto: <a href="mailto:support@webonjour.com">support@webonjour.com</a></p>                  
                  <p className="mt-5">Effective Date- 28 February 2023</p>                  
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
