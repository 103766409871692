import axios from 'axios';
import config from '../config/config';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import busdabi from '../ABI/busdABI';

export const subscribe_now = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/subscribe`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err)
  }
}

export const updatebuy_history = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/updatebuyhistory`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err)
  }
}

export const get_buyhistory = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/buyhistory`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err)
  }
}

export const getCurAddr = async () => {
  if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="trust"){
    try{
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56
      });
      // const provider = new WalletConnectProvider({
      //   rpc: {
      //     97: "https://data-seed-prebsc-1-s3.binance.org:8545/",
      //   },
      //   chainId: 97
      // });

      provider.on("connect", () => {
      });
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if(result[0]!=undefined){
        if (config.networkVersion  == network) {
          localStorage.setItem("wallettype","trust")
          localStorage.setItem("account",result[0]);
          let bnbbalance = await web3.eth.getBalance(result[0]);
          var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
          let busdbalance = await busdContract.methods.balanceOf(result[0]).call();
          var data = {
             address : result[0],
             busdvalue  : busdbalance,
             bnbvalue : bnbbalance,
             "provider" : provider,
          }
          return data
        } else {
         await provider.disconnect();
        }
     }
    }catch(err){
      localStorage.clear();
      window.location.reload()
    }
  }
  else if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="metamask"){
   if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum.enable()
          let web3 = new Web3(window.ethereum);
          if (window.ethereum) {
            let netid = await web3.eth.net.getId();
            if (
              netid ==
              config.networkVersion
            ) {
              var result = await web3.eth.getAccounts();
              var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
              let busdbalance = await busdContract.methods.balanceOf(result[0]).call();
              let bnbbalance = await web3.eth.getBalance(result[0]);
              if(localStorage.getItem("account")){
                localStorage.setItem("wallettype","metamask")
                localStorage.setItem("account",result[0]);
              }
              var data = {
               address : result[0],
               busdvalue  : busdbalance,
               bnbvalue : bnbbalance
            }
            return data
            }
          }
        }
      }catch(err){
        localStorage.clear();
        console.log(err,"===========errrrrrrrrrrrdata")
     }
    }
  }
}